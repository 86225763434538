<template>
  <v-card class="param " >
    <v-card-title>
      <h4 class="title-card-settings">
        <v-icon size="18" class="mx-2">fa-solid fa-gear</v-icon>
        {{ $t("views.settings.settings") }}
      </h4>
      <v-spacer />
      <v-btn depressed text color="white" @click.stop="$emit('closeForm')">
        <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
      </v-btn>
    </v-card-title >
    <v-card-text class="pa-0 ma-0">
      <v-container fluid >
        <v-tabs vertical  class="pl-2 ma-0" id="tabs-settings" @change="selectedTab()" v-model="tab" >
          <v-tab v-if="typeUser == 1" href="#DriverVehicle" class="py-1 my-1">
            <v-icon left> fa-solid fa-user-gear </v-icon>
            <b>{{ $t("views.settings.driver_vehicle") }}</b>
          </v-tab>
          <v-tab v-if="typeUser == 1" href="#Advance" class="py-1 my-1">
            <v-icon left> fa-solid fa-hand-holding-dollar </v-icon>
            <b>{{ $t("views.settings.advance") }}</b>
          </v-tab>
          <v-tab v-if="typeUser == 1" href="#CounterOffer" class="py-1 my-1">
            <v-icon left> fa-solid fa-percent</v-icon>
            <b>{{ $t("views.settings.counteroffer") }}</b>
          </v-tab>
          <v-tab v-if="typeUser == 1" href="#PostingRules" class="py-1 my-1">
            <v-icon left> fa-solid fa-ruler-combined</v-icon>
            <b> {{ $t("views.settings.posting_rules") }}</b>
          </v-tab>
          <v-tab href="#Language" class="py-1 my-1">
            <v-icon left> fa-solid fa-earth-americas</v-icon>
            <b> {{ $t("views.settings.language") }}</b>
          </v-tab>

          <v-tab-item v-if="typeUser == 1 && tab === 'DriverVehicle'" value="DriverVehicle"  class="pl-4">
            <DriverVehicle :key="key"/>
          </v-tab-item>
          <v-tab-item v-if="typeUser == 1 && tab === 'Advance'" value="Advance">
            <Advance :key="key" />
          </v-tab-item>
          <v-tab-item v-if="typeUser == 1 && tab === 'CounterOffer'" value="CounterOffer" class="pl-4">
            <CounterOffer :key="key" />
          </v-tab-item>
          <v-tab-item v-if="typeUser == 1 && tab === 'PostingRules'" value="PostingRules" class="pl-1">
            <PostingRules :key="key" />
          </v-tab-item>
          <v-tab-item value="Language" v-if="tab === 'Language'" class="pl-4">
            <Language :key="key" />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    Language: () =>
      import(/* webpackChunkName: "Language" */ "@/components/views/Settings/Language.vue"),
    CounterOffer: () =>
      import(/* webpackChunkName: "CounterOffer" */ "@/components/views/Settings/Counteroffer.vue"),
    PostRadius: () =>
      import(
        /* webpackChunkName: "PostRadius" */ "@/components/views/Settings/PostingRules/PostRadius/PostRadius.vue"
      ),
    DriverVehicle: () =>
      import(
        /* webpackChunkName: "DriverVehicle" */ "@/components/views/Settings/DriverVehicle/DriverVehicle.vue"
      ),
    Advance: () =>
      import(/* webpackChunkName: "Advance" */ "@/components/views/Settings/Advance.vue"),
    PostingRules: () =>
      import(
        /* webpackChunkName: "PostingRules" */ "@/components/views/Settings/PostingRules/PostingRules.vue"
      ),
  },
  data: () => ({
    tab: "DriverVehicle",
    key: 1,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    typeUser() {
      let unlockUser,
        user = this.user;
      if (typeof user !== "undefined") {
        unlockUser = JSON.parse(user);
      }
      return user !== undefined ? unlockUser.user.typeUser : "";
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.settings"),
    };
  },
  methods: {
    selectedTab() {
      this.key += 1;
    },
  },
};
</script>
<style scoped>

.param {
 padding: 0;
 margin: 0;
}

.param .v-card__title {
  background-color: #0b0045 !important;
}

.title-card-settings,
.title-card-settings >>> .fa-solid {
  color: #fff !important;
  font-size: 15px;
}

#tabs-settings .v-tab {
  justify-content: left !important;
}

#tabs-settings >>> .v-tabs-items {
  height: 470px;
}
</style>
